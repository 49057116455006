.selectBox {
  position: relative;
  .selectVal {
    padding-left: 12px;
    margin-left: 6px;
    border-left: 1px solid #93aebc;
    // :hover {
    //   cursor: pointer;
    // }
    .DownIcon {
      width: 20px;
      height: 20px;
    }
    .select-caret {
      transition: transform 0.3s;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .MuiTypography-h4 {
      font-size: 1.1rem !important;
    }
  }
  .selectList {
    display: none;
    position: absolute;
    top: 40px;
    z-index: 999;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 12px 0;
    left: 4px;
    .selectItem {
      padding: 0px 16px;
      h6 {
        color: #000;
      }
      :hover {
        cursor: pointer;
      }
    }
  }
  .selectListDark {
    background-color: #fff !important;
  }
}

.show {
  display: block !important;
}
