#stake-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .stake-gird-container {
    position: relative;
    z-index: 1;
  }
  .rebase-timer {
    margin: 0 !important;
    line-height: 110% !important;
  }

  .stake-tab-buttons {
    margin-bottom: 0.25rem;
  }

  .stake-table-panel,
  .stake-tab-panel > div.MuiBox-root {
    width: 100%;
    padding: 0;
  }
  .mobile .stake-tab-panel,
  .mobile .stake-tab-panel > div.MuiBox-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }

  .ohm-card .card-header {
    position: relative;
  }
  .ohm-card .header {
    min-height: 3em;
  }
  .ohm-card.secondary .card-header {
    min-height: 33px !important;
    height: 20px;
  }
  .stake-note {
    text-align: center;
    font-style: italic;
  }
}

.migrate-sXPH-button {
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 1em;
  display: flex;
  align-items: center;
  color: #f08080;
  line-height: 30px;
  svg {
    margin-right: 8px;
    color: inherit;
  }
}

.migrate-sXPH-button.complete {
  color: #35ee66 !important;
}

.stake-top-metrics {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: center;
  margin-top: 10px;
  h5 {
    font-weight: 400;
  }
  h4 {
    font-weight: 500;
  }
  .stake-apy,
  .stake-tvl,
  .stake-index {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-overflow: ellipsis;
    // white-space: nowrap;
    overflow: hidden;
  }
}

.mobile .stake-top-metrics {
  .stake-apy,
  .stake-tvl,
  .stake-index {
    align-items: flex-start;
  }
}

.ohm-logo-tiny {
  height: 14px;
  width: 14px;
  vertical-align: middle;
  background-color: transparent;
  align-self: center;
  justify-self: center;
}

.logo-holder {
  align-items: center;
  justify-content: center;
  text-align: center;
  display: flex;
  background: none;
  border: 0 !important;
  border-radius: inherit;
  color: #555;
}
.invite-address-ipt {
  width: 100%;
  margin-bottom: 7px !important;
}
.invite-address-right {
  margin-bottom: 15px;
}
.invite-btn {
  width: 240px;
}
.stake-action-area {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: -30px;
}

.mobile .stake-action-area {
  margin-top: 20px;
}

.stake-action-row {
  justify-content: space-around;
  align-items: center;
  height: auto;
  padding-bottom: 1rem;
}

.mobile .stake-action-row {
  justify-content: center;
}

.stake-action-row [role="tabpanel"] {
  width: 99%;
  min-width: 162px;
  max-width: 220px;
  margin: 5px;
}

.mobile .stake-action-row {
  flex-direction: column;
  [role="tabpanel"] {
    max-width: 100%;
    width: 100% !important;
  }
}

.ohm-input {
  max-width: 542px;
  width: 100%;
  margin: 5px !important;
}

.stake-button {
  align-self: center;
  width: 100%;
  min-width: 163px !important;
  max-width: 542px !important;
  height: 43px !important;
}

.mobile .stake-button {
  width: 100% !important;
  max-width: 542px;
  align-self: center;
  justify-self: center;
}

.staking-area {
  margin: 13px 10px 13px 10px;
  position: relative;
  z-index: 1;
}

.stake-lp-button {
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
  svg {
    margin-left: 4px;
    vertical-align: middle;
    font-size: 1.1em;
  }
  &:hover {
    svg {
      color: inherit;
    }
  }
}

.stake-user-data {
  justify-content: center;
  margin: auto;
  padding: 0 5px;
}

.tablet .stake-pool,
.mobile .stake-pool {
  .pool-card-top-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .ohm-pairs p {
      margin-bottom: 0 !important;
    }
  }

  .pool-data {
    margin-top: 15px;
    width: 100%;
    .pool-data-row {
      display: flex;
      justify-content: space-between;
      line-height: 225%;
    }
  }
  .stake-lp-button {
    margin-top: 10px;
    svg {
      margin-left: 10px !important;
    }
  }
}

.stake-wallet-notification {
  text-align: center;
  .wallet-menu {
    text-align: center;
    .connect-button {
      max-width: 300px !important;
      min-width: 240px !important;
      margin-bottom: 15px;
    }
  }
}

.warmup-container {
  display: flex;
  align-items: center;
}
.ohm-card {
  backdrop-filter: none !important;
}
