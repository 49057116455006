.MuiPaper-root.ohm-card.pledge-data-card {
  margin-bottom: 10px;
  .pledge-data-box {
    width: 100%;
    & > .MuiBox-root {
      width: 50%;
    }
  }
  // .reward-data-title {
  //   margin-bottom: 34px;
  // }
}
.MuiPaper-root.ohm-card.pledge-data-table {
  padding: 0;
  .MuiTableHead-root {
    border-bottom: 1px solid #999;
  }
}
