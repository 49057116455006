#speed-modal {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  .speed-popover {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    background: #fefefe;
    border-radius: 10px;
    .speed-title {
      margin-bottom: 30px;
      h3 {
        align-self: center;
        justify-self: center;
        width: 75%;
        text-align: center;
      }
    }
    .modal-content {
      .content-title {
        width: 100%;
        margin-bottom: 10px;
      }
      .select-box {
        width: 100%;
        margin-bottom: 10px;
        .MuiOutlinedInput-root {
          width: 100%;
          border: 1px solid #181a1d;
          border-radius: 8px;
          &::before,
          &::after {
            display: none;
          }
        }
        .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        #select {
          width: 100%;
        }
      }
      .fee-box {
        margin-bottom: 30px;
      }
      .confirm-btn {
        width: 100%;
      }
    }
  }
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-root.MuiPopover-paper {
  border: 1px solid #a7a7a7;
}
.MuiList-root.MuiMenu-list {
  padding: 0;
  & > li {
    height: 44px;
    background: #fefefe;
    border-width: 0.5px 0px;
    border-style: solid;
    border-color: #a7a7a7;
    &.Mui-selected {
      background: #e0e2e3;
    }
  }
}
