.MuiPaper-root.ohm-card.reward-data-claim-card {
  margin-bottom: 10px;
  .reward-data-title {
    margin-bottom: 34px;
  }
}
.MuiPaper-root.ohm-card.reward-data-table {
  padding: 0;
  .MuiTableHead-root {
    border-bottom: 1px solid #999;
  }
}
