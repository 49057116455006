#dao-rewards-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .tabs-box {
    width: 97%;
    max-width: 833px;
    .yb-tab-buttons {
      .MuiTab-textColorPrimary.Mui-selected {
        color: #000;
      }
    }
  }
  [role="tab"] {
    margin-right: 40px;
  }
  [role="tabpanel"] {
    width: 100%;
    // max-width: 833px;
    > div.MuiBox-root {
      width: 100%;
      padding: 0;
      display: flex;
      padding-top: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.mobile {
  #simple-tab {
    font-size: 18px;
  }
  #dao-rewards-view {
    [role="tab"] {
      margin-right: 20px;
    }
  }
}
