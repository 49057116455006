#sc-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .amount-card {
    height: 100px;
    padding: 35px 20px;
    margin-bottom: 24px;
    h5 {
      width: 50%;
    }
  }
  .tabs-box {
    width: 97%;
    max-width: 833px;
    .yb-tab-buttons {
      .MuiTab-textColorPrimary.Mui-selected {
        color: #000;
      }
    }
  }
  // .yp-panel-box {
  //   width: 97%;
  //   max-width: 833px;
  //   > div.MuiBox-root {
  //     width: 100%;
  //     padding: 0;
  //   }
  // }
  [role="tab"] {
    margin-right: 40px;
  }
  [role="tabpanel"] {
    width: 100%;
    // max-width: 833px;
    > div.MuiBox-root {
      width: 100%;
      padding: 0;
      display: flex;
      padding-top: 10px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.mobile {
  #simple-tab {
    font-size: 18px;
  }
  #sc-view {
    [role="tab"] {
      margin-right: 20px;
    }
  }
}
