#claim-profit-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .dr-crad {
    padding: 35px 50px;
  }
  .claim-profit-card {
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 24px;
    }
    .claim-item-title {
      margin-bottom: 7px;
      color: #676b74;
    }
    .claim-item-amount {
      margin-bottom: 8px;
      color: #181a1d;
    }
    .claim-btn {
      width: 96px;
    }
  }

  .claim-profit-table {
    padding: 0;
    .MuiTableHead-root {
      border-bottom: 1px solid #999;
    }
  }
}

// @media screen and (max-width: 768px) {
.mobile {
  #claim-profit-view {
    .claim-profit-card {
      .claim-item-title {
        margin-bottom: 0;
      }
    }
  }
}
