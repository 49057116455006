.dapp-topbar {
  width: 100%;
  align-items: center;
  button {
    white-space: nowrap !important;
    overflow: hidden !important;
    margin: 6px !important;
    min-width: fit-content !important;
    &#hamburger {
      padding: 11px !important;
    }
    &.toggle-button {
      padding: 9.5px !important;
    }
  }
  .stake-connect {
    // background: red;
    // background: linear-gradient(90.1deg, #c9c331 0.07%, #31c98d 100.95%);
    // border-radius: 8px;
    color: #010704;
    border: 2px solid !important;
    border-image-source: linear-gradient(90.1deg, #31c98d 0.07%, #c9c331 100.95%) !important;
    clip-path: inset(0 round 4px) !important;
    // background-color: red;
    background: linear-gradient(90.1deg, #c9c331 0.07%, #31c98d 100.95%);
    border-image-slice: 1 !important;
    border-image-repeat: stretch !important;
    // color: ;
  }
}

.tablet .dapp-topbar {
  justify-content: space-between;
}
.app .stake-topbar {
  justify-content: space-between;
}
.pending-txn-container {
  display: flex;
  align-items: center;
  .MuiButton-label {
    margin-right: 0;
    transition: all 0.33s ease-out;
  }
}
#ohm-popper-pending {
  .MuiPaper-root.ohm-menu {
    a {
      padding-right: 20px !important;
    }
  }
}
.caret-down {
  position: absolute;
  right: 0.4rem;
}
.hovered-button {
  .MuiButton-label {
    margin-right: 0.5rem;
    transition: all 0.33s ease-out;
  }
}
.right-drawer {
  .MuiDrawer-paperAnchorRight {
    width: 360px;
    padding: 0 15px;
    .token-box-container {
      margin-bottom: 60px;
      .token-box {
        width: 100%;
        height: 69px;
        background: #f2f5f7;
        border: 1px solid #e6e8ec;
        border-radius: 8px;
        padding: 14px 20px;
        margin-bottom: 16px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .add-token-container {
      margin-bottom: 60px;
      .add-token-box {
        width: 100%;
        height: 50px;
        padding: 14px 20px;
        background: #ffffff;
        border: 1px solid #e6e8ec;
        border-radius: 8px;
        margin-bottom: 16px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .invite-container {
      margin-bottom: 100px;
      .invite-title {
        margin-bottom: 10px;
      }
      .invite-link {
        width: 100%;
        height: 60px;
        padding: 15px 16px;
        background: rgba(0, 111, 255, 0.05);
        border: 1px solid #006fff;
        border-radius: 8px;
        white-space: wrap;
        & > p {
          word-break: break-all;
        }
      }
    }
  }
}
.right-drawer-close {
  width: 100%;
  height: 120px;
  .close-box {
    width: 28px;
    height: 28px;
    cursor: pointer;
  }
}
.topbar-link {
  padding: 15px 25px;
  position: relative;
  text-decoration: none !important;
  span {
    // color: red;
    position: absolute;
    right: 10px;
    top: 13px;
    font-size: 10px;
  }
  .topbar-border {
    width: 36px;
    height: 3px;
    background: linear-gradient(90.1deg, #c9c331 0.07%, #31c98d 100.95%);
    // transform: translate(50%, 0);
    position: absolute;
    left: 50%;
    bottom: 5px;
    margin-left: -18px;
    border-radius: 10px 10px 0 0;
    display: none;
  }
  &:hover {
    span {
      //   color: red;
      background-image: -webkit-linear-gradient(right, rgba(201, 195, 49, 1), rgba(49, 201, 141, 1));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .topbar-border {
      display: block;
    }
  }
}
.active-link {
  color: rgba(0, 166, 100, 1) !important;
  span {
    //   color: red;
    background-image: -webkit-linear-gradient(right, rgba(201, 195, 49, 1), rgba(49, 201, 141, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .topbar-border {
    display: block;
  }
}

.stake-appbar {
  background: rgba(4, 14, 10, 0.3) !important;
  backdrop-filter: blur(50px) !important;
  padding: 0 !important;
}

@media screen and(max-width:680px) {
  .right-drawer {
    .MuiDrawer-paperAnchorRight {
      width: 280px;
      padding-top: 20px;
      .token-box-container,
      .add-token-container,
      .invite-container {
        margin-bottom: 20px;
      }
    }
  }
}
